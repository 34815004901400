<template>
  <div class="job-layout">
    <van-nav-bar left-arrow @click-left="()=>$router.go(-1)" />
    <div class="det-content">
      <div class="det-content-title">
        {{detailsMap.jobFairName}}
      </div>
      <div class="det-content-address">
        <div class="det-content-address-box flex">
          <div class="det-content-address-l">时间：</div>
          <div class="det-content-address-r flex_1">{{getTimeString(detailsMap.eventEndTime,detailsMap.eventStartTime) ||'暂无'}}</div>
        </div>
        <div class="det-content-address-box flex">
          <div class="det-content-address-l">场所：</div>
          <div class="det-content-address-r flex_1">{{detailsMap.hostUnit}}</div>
        </div>
        <div class="det-content-address-box flex">
          <div class="det-content-address-l">地址：</div>
          <div class="det-content-address-r flex_1">{{detailsMap.fairAddress||'暂无'}}</div>
        </div>
      </div>
      <div class="det-content-start flex column middle center">
        <div class="det-content-start-top">{{$dayjs(detailsMap.eventStartTime).format('YYYY-MM-DD')}}</div>
        <div class="det-content-start-bottom">签到开始时间</div>
      </div>
      <div class="det-content-footer flex middle center">
        <van-button @click="goToScanCode" round style="width:80%;" icon="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/scan_icon.png" color="#4097FF">
          扫码签到
        </van-button>
      </div>
    </div>

    <!-- 签到弹窗 -->
    <van-popup v-if="!!companyInfoMap" v-model="checkInShow" position="bottom" :style="{ height: 'auto' }">
      <div class="check-in-mask">
        <div class="check-in-mask-top flex">
          <div class="check-in-mask-top-l">参会企业</div>
          <div class="check-in-mask-top-r flex_1">{{companyInfoMap.enterpriseName}}</div>
        </div>
        <div class="check-in-mask-top flex">
          <div class="check-in-mask-top-l">参会展位</div>
          <div class="check-in-mask-top-r site flex_1">{{companyInfoMap.standNumber==0?'未分配':companyInfoMap.standNumber}}</div>
        </div>
        <van-divider class="vanDivider-line" />
        <!-- 角色+车牌+手机 -->
        <div v-if="!!companyInfoMap.attendeeList">
          <div v-for="(company,idx) in companyInfoMap.attendeeList" :key="idx">
            <div class="company-info flex middle">
              <div class="company-info-l flex column">
                <div class="company-info-l-top">{{company.realName}}</div>
                <div class="company-info-l-bom">{{company.position}}</div>
              </div>
              <div class="company-info-r flex column">
                <div class="company-info-r-top flex middle">
                  <div class="company-info-r-top-car">车牌</div>
                  <div class="company-info-r-top-car-num">{{company.cardId}}</div>
                </div>
                <div class="company-info-r-top flex middle">
                  <div class="company-info-r-top-car">手机</div>
                  <div class="company-info-r-top-car-num">{{company.mobile}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂无数据" />
        <div class="check-in-btn-submit flex middle center">
          <van-button @click="confirmCheckIn" round style="width:80%;" color="#4097FF">
            确认签到
          </van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

  <script>
// import { mapActions } from "vuex";
export default {
  name: 'Details',
  data () {
    return {
      checkInShow: false,
      detailsMap: {},
      checkInInfoMap: {},
      companyInfoMap: {},
      flag: ''
    }
  },
  onLoad () {

  },
  mounted () {
    this.flag = this.$route.query.flag
    this.isCheckInInfo()
    this.homeListDetails( this.$route.query.id || '' )
  },
  beforeRouteLeave ( to, from, next ) {
    localStorage.removeItem( 'checkInInfo' )
    // 清空本地数据的相关逻辑
    next();
  },
  methods: {

    isCheckInInfo () {
      this.checkInInfoMap = JSON.parse( localStorage.getItem( 'checkInInfo' ) )
      this.checkInShow = !!( this.checkInInfoMap && this.checkInInfoMap.enterpriseId )
      if ( this.checkInInfoMap ) {
        if ( this.checkInInfoMap.identifyType == 1 ) {
          this.workerClickSign( {
            id: this.checkInInfoMap.id,
            jobFairId: localStorage.getItem( 'jobFairId' ) || ''
          } )
          localStorage.removeItem( 'checkInInfo' )
        }
        if ( this.checkInInfoMap.identifyType == 2 ) {
          this.companyInfo( { id: this.checkInInfoMap.id, jobFairId: localStorage.getItem( 'jobFairId' ) || '' } )
        }
      }
      localStorage.removeItem( 'checkInInfo' )
    },
    getStartTime () {

    },
    homeListDetails ( id ) {
      this.$api.home
        .homeListDetails( { id } )
        .then( res => {
          this.detailsMap = res.data
          localStorage.setItem( 'jobFairId', res.data.id )
        } )
        .catch( () => {

        } )
    },
    companyInfo ( params ) {
      this.$api.home
        .companyInfo( params )
        .then( res => {
          if ( res.data ) {
            this.companyInfoMap = res.data
          }
        } )
        .catch( () => {

        } )
    },
    companyClickSign ( params ) {
      this.$api.home
        .companyClickSign( params )
        .then( res => {
          if ( res.code === 500 ) {
            this.$myJs.tip( res.msg );
            return false
          }
          if ( res.code === 200 ) {
            this.$myJs.tip( '签到成功' );
          }

        } )
        .catch( () => {

        } )
    },
    workerClickSign ( params ) {
      this.$api.home
        .workerClickSign( params )
        .then( res => {
          if ( res.code === 500 ) {
            this.$myJs.tip( res.msg );
            return false
          }
          if ( res.code === 200 ) {
            this.$myJs.tip( '签到成功' );
          }

        } )
        .catch( () => {

        } )
    },
    // 过滤时间
    getTimeString ( endTime, startTime ) {
      if ( !endTime && !startTime ) return
      //将传入的日期参数转换为时间戳
      let st = new Date( startTime ?.replace( /-/g, "/" ) );
      let et = new Date( endTime ?.replace( /-/g, "/" ) );
      //获取时间差
      let delta = et - st;
      let days = delta / 1000 / 60 / 60 / 24;
      let shour = st.getHours() <= 9 ? '0' + st.getHours() : st.getHours();
      let ehour = et.getHours() <= 9 ? '0' + et.getHours() : et.getHours();
      let sminute = st.getMinutes() <= 9 ? '0' + st.getMinutes() : st.getMinutes();
      let eminute = et.getMinutes() <= 9 ? '0' + et.getMinutes() : et.getMinutes();
      if ( days < 1 ) {
        return `${ st.getFullYear() }.${ ( st.getMonth() + 1 ) }.${ st.getDate() } ${ shour }:${ sminute }-${ ehour }:${ eminute }`;
      } else {
        return `${ st.getFullYear() }.${ st.getMonth() + 1 }.${ st.getDate() } ${ shour }:${ sminute } - ${ et.getFullYear() }.${ et.getMonth() + 1 }.${ et.getDate() } ${ ehour }:${ eminute }`;
      }
    },

    // ...mapActions("user", ["login"]),
    goToScanCode () {
      this.$router.push( '/scanSpare' )
    },
    confirmCheckIn () {
      this.companyClickSign( {
        id: this.checkInInfoMap.id,
        jobFairId: localStorage.getItem( 'jobFairId' ) || ''
      } )
      localStorage.removeItem( 'checkInInfo' )
      this.checkInShow = false
    }
  }
}
</script>

  <style lang="less" scoped>
/deep/ .van-nav-bar .van-icon {
  color: #000000;
  font-size: 18px;
}

.det-content {
  padding: 20px 15px;
  .det-content-title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }
  .det-content-address {
    margin-top: 20px;
    .det-content-address-box {
      margin-bottom: 10px;
      .det-content-address-l {
        font-size: 14px;
        font-weight: 400;
        color: #8c8c8c;
      }
      .det-content-address-r {
        font-size: 14px;
        font-weight: 500;
        color: #262626;
      }
    }
  }
  .det-content-start {
    margin-top: 40px;
    background: #ecf5ff;
    border-radius: 9px 9px 9px 9px;
    padding: 16px 0;
    .det-content-start-top {
      font-size: 24px;
      font-weight: bold;
      color: #262626;
    }
    .det-content-start-bottom {
      font-size: 14px;
      font-weight: 400;
      color: #595959;
      margin-top: 2px;
    }
  }
  .det-content-footer {
    width: 100%;
    margin-top: 137px;
  }
}

// 确实签到弹窗
.check-in-mask {
  padding: 30px 20px;
  .check-in-mask-top {
    margin-bottom: 8px;
    .check-in-mask-top-l {
      font-size: 16px;
      font-weight: 400;
      color: #8c8c8c;
    }
    .check-in-mask-top-r {
      margin-left: 20px;
      font-size: 16px;
      font-weight: 500;
      color: #262626;
      &.site {
        color: #4097ff;
      }
    }
  }
  .vanDivider-line {
    margin: 20px 0;
  }
  .company-info {
    margin-bottom: 23px;
    .company-info-l {
      margin-right: 80px;
      margin-top: -7px;
      .company-info-l-top {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        margin-bottom: 6px;
      }
      .company-info-l-bom {
        font-size: 13px;
        font-weight: 400;
        color: #595959;
      }
    }
    .company-info-r {
      .company-info-r-top {
        margin-bottom: 7px;
        .company-info-r-top-car {
          padding: 0 7px;
          background: #f5f5f5;
          border-radius: 4px 4px 4px 4px;
          height: 22px;
          line-height: 22px;
          text-align: center;
          font-size: 13px;
          font-weight: 400;
          color: #595959;
        }
        .company-info-r-top-car-num {
          font-size: 13px;
          font-weight: 400;
          color: #1f1f1f;
          margin-left: 10px;
        }
      }
    }
  }
}
/deep/ .van-popup {
  border-radius: 10px 10px 0 0;
}
</style>
